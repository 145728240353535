import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    sortBy: 'priceAsc',
  };
  export const FilterHotelSlice = createSlice({
    name: 'filter-hotels',
    initialState,
    reducers: {
      sortByLowestPriceHotels(state, action) {
        state.sortBy = action.payload;
      },
    },
  });
  export const {
    sortByLowestPriceHotels
  } = FilterHotelSlice.actions;
  export default FilterHotelSlice.reducer;